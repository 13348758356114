import { useGLTF, useTexture, OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { Geometry, Base, Subtraction, Addition } from "@react-three/csg";
import advancedMaterialData from "../assets/advancedMaterial/texture.js";
import { getMaterialDetail } from "../api";
import {baseUrl, isDev} from '../utils/config';

/* 内衬 */
function Inner(props) {
  const csg = useRef();
  let advancedMaterial = advancedMaterialData[props.texture];
  if(props.texture){
    advancedMaterial = {
      map: isDev ? props.textureData['合并结果'].slice(26) : props.textureData['合并结果']
    }
  } else {
    advancedMaterial = advancedMaterialData[props.texture]
  }
  /*const advancedMaterial = {
    map: props.textureData['合并结果']
  };*/
  const advancedMaterialT = useTexture(advancedMaterial);

  return (
    <mesh receiveShadow castShadow {...props} scale={5 / 100}>
      <Geometry ref={csg} computeVertexNormals>
        <Base
          name="base"
          position={props.Direction === "上方" ? [0, 1.5, 0] : [0, 0.5, 0]}
          scale={props.Direction === "上方" ? [5.8, 0.5, 4.4] : [5.8, 1, 4.4]}
        >
          <boxGeometry />
        </Base>
        {props.shape === "圆形" ? (
          <Subtraction
            position={
              props.Direction === "上方"
                ? [0, 1.5 - (1 - props.Depth / 100), 0]
                : [0, 0.5 + (1 - props.Depth / 100), 0]
            }
            scale={
              props.Direction === "上方"
                ? [(2 * props.height) / 100, 0.5, (2 * props.height) / 100]
                : [(2 * props.height) / 100, 1, (2 * props.height) / 100]
            }
          >
            <cylinderGeometry args={[1, 1, 1, 64]} />
          </Subtraction>
        ) : (
          <Subtraction
            position={
              props.Direction === "上方"
                ? [0, 1.5 - (1 - props.Depth / 100), 0]
                : [0, 0.5 + (1 - props.Depth / 100), 0]
            }
            scale={
              props.Direction === "上方"
                ? [(5.7 * props.height) / 100, 0.5, (4.2 * props.height) / 100]
                : [(5.7 * props.height) / 100, 1, (4.2 * props.height) / 100]
            }
          >
            <boxGeometry />
          </Subtraction>
        )}
      </Geometry>

      <meshStandardMaterial key={props.texture} {...advancedMaterialT} displacementScale={0.01} />
      {props.texture === 0 ? (
        <meshStandardMaterial color={"red"} displacementScale={0.01} />
      ) : null}
    </mesh>
  );
}

export default function Model9(props) {
  let url =  props.outerPrint.glb
  console.log(url)
  if(isDev && url.indexOf('http') > -1){
    //本地开发环境跨域解决
    url = url.slice(26)
  }

  const { nodes, materials } = useGLTF(url);
  console.log("模型文件信息：",nodes)
  const setActive = () => props.onClick(["setActive"]);
  console.log("ID：",props.modelId)
  //const texture = useTexture(props.childData);//映射的图片
  const ref = useRef();
  const orbit = useRef();

  const InnerList = props.innerList;
  const Incolor = props.inner;
  const Opacity = parseFloat(Incolor.color.alpha / 100);
  console.log(props.info3d);
  // 位置缩放等信息
  const px = props.info3d.position.x;
  const py = props.info3d.position.y;
  const pz = props.info3d.position.z;
  const rx = props.info3d.rotation.x;
  const ry = props.info3d.rotation.y;
  const rz = props.info3d.rotation.z;
  const sx = props.info3d.scale.x;
  const sy = props.info3d.scale.y;
  const sz = props.info3d.scale.z;
  //有无材质
  let advancedMaterial = props.outerPrint.tech.advancedMaterial
    ? {
        map: props.outerPrint.tech.materialProperty["合并结果"],
        metalnessMap: baseUrl + "/advancedMaterialTextures/default_black.png",
        roughnessMap: baseUrl + "/advancedMaterialTextures/default_white.png",
      }
    : {
        map: props.childData,
        metalnessMap: baseUrl + "/advancedMaterialTextures/default_black.png",
        roughnessMap: baseUrl + "/advancedMaterialTextures/default_white.png",
      };
  advancedMaterial.map = props.childData;
  //高级材质
  if (props.techImages !== undefined && props.techImages.length !== 0) {
    console.log("使用了mixulr");
    if (props.techImages[0].mixUrl) {
      advancedMaterial.roughnessMap = props.techImages[0].mixUrl;
    }
    if (props.techImages[0].url) {
      advancedMaterial.metalnessMap = props.techImages[0].url;
    }
  } else {
    // console.log("没有使用了mixulr");
    advancedMaterial.roughnessMap =
      baseUrl + "/advancedMaterialTextures/default_white.png";
    advancedMaterial.metalnessMap =
      baseUrl + "/advancedMaterialTextures/default_black.png";
  }

  // console.log(advancedMaterial.map);
  // console.log(advancedMaterial.roughnessMap);
  // console.log(advancedMaterial.metalnessMap);

  const advancedMaterialT = useTexture(advancedMaterial);
  advancedMaterialT.map.flipY = false;
  advancedMaterialT.roughnessMap.flipY = false;
  advancedMaterialT.metalnessMap.flipY = false;

  function UVArea({ view3D }) {
    return (
      <meshStandardMaterial
        {...advancedMaterialT}
        transparent
        metalness={1.0}
        roughness={1.0}
        opacity={view3D ? Opacity : 1}
        side={THREE.DoubleSide}
      />
    );
  }

  switch (props.viewMode) {
    case "2D":
      return (
        <>
          <group
            {...props}
            dispose={null}
            name="box2D"
            scale={1.2}
            rotation={[0, 180, 0]}
          >
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Cube.geometry}
              material={nodes.Cube.material}
              // position={[0, 0.04, -0.112]}
              onClick={setActive}
            >
              <UVArea view3D={true} />
            </mesh>
          </group>
          <OrbitControls ref={orbit} />
        </>
      );
    case "3D":
      return (
        <group
          ref={ref}
          {...props}
          dispose={null}
          name="box3D"
          position={[px - 0.09, py - 0.001, pz - 0.04]}
          scale={[sx, sy, sz]}
          // scale={[sx * 1.14, sy * 1.14, sz * 1.14]}
          //rotation={[rx + 1.57, ry + 180, rz - 0.88]}
          // rotation={[rx + 1.57, ry + 3.15, rz + 1.2]}
          // position={[px, py, pz]}
          // scale={[sx * 114, sy * 114, sz * 114]}
          rotation={[rx, ry, rz]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube.geometry}
            material={nodes.Cube.material}
            // position={[0, 0.04, -0.112]}
            onClick={setActive}
          >
            <UVArea view3D={true} />
          </mesh>

          {/* 内衬部分的代码 */}
          {/* {InnerList.map((inner, index) => {
            console.log(index);
            return (
              <Inner
                shape={inner.plane.shape}
                key={index}
                height={inner.plane.overallThickness}
                Depth={inner.plane.grooveThickness}
                Direction={inner.plane.direction}
                texture={inner.texture ? inner.texture : 0}
                textureData={inner.textureData ? inner.textureData : {}}
                baseTexture={props.childData}
              />
            );
          })} */}
        </group>
      );
    default:
      return;
  }
}

import React from 'react';
import styles from './tail.module.css'
import '../../App.css'

class Tail extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.tailText}>Copyright ©2022 | 朗宾公司版权所有</div>
                <div className={styles.tailText}>浙ICP备050123332 | 浙公网安备33010602011233 | 经营许可证号 浙B2-20210400</div>
            </div>
        );
    }
}

export default Tail;